<template>
  <CRow class="justify-content-center">
    <CCol col="12" md="9" lg="7">
      <CCard class="p-4 pb-5 shadow-none col-12" color="second-card-bg">
        <CCardBody>
          <div class="cheader">
            <a :href="homepage_url">
              <logo-wrap :auth="true" />
            </a>
            <div class="d-flex align-items-center">
              <div class="mr-3 cursor-pointer" @click="darkMode = !darkMode">
                <CIcon v-if="darkMode" name="cil-sun"/>
                <CIcon v-else name="cil-moon"/>
              </div>
              <CSelect
                :value.sync="locale"
                :options="$store.state.localeOptions"
                class="mb-0"
              />
            </div>
          </div>
          <h1 class="title">{{$t('formSignUp.title')}}</h1>
          <template v-if="linkHasSended">
            <h5>{{$t('formSignUp.subtitle')}}</h5>
          </template>
          <template v-else>
            <h5>{{$t('formSignUp.confirm')}}</h5>
            <CLoadingButtonCustom
              color="primary"
              class="shadow-none mb-2"
              :loading="loadingRequest"
              :disabled="loadingRequest"
              @click.native="sendLink"
            >
              {{$t('formSignUp.sendLink')}}
            </CLoadingButtonCustom>
            <div>
              <CButton
                class="shadow-none"
                variant="outline"
                color="primary"
                @click="logoutBtnClick"
              >
                {{$t('formSignUp.logout')}}
              </CButton>
            </div>
          </template>
          <div v-if="showSignInLink">
            <CLink :to="{name: 'signin'}">
              {{$t('formSignUp.signIn')}}
            </CLink>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LogoWrap from "@/components/LogoWrap";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "Recovery",
  components: {
    LogoWrap
  },
  data () {
    return {
      loadingRequest: false,
      linkHasSended: false,
      showSignInLink: false
    }
  },
  computed: {
    locale: {
      get () {
        return this.$root.$i18n.locale
      },
      set (lang) {
        this.$root.$i18n.locale = lang
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
    },
    darkMode: {
      get () {
        return this.$store.getters.darkMode
      },
      set (val) {
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'darkMode',
          value: val
        })
      }
    },
    homepage_url() {
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if(hp && hp.slice(-1) !== '/'){
        hp += '/'
      }
      if(this.locale === 'ru'){
        hp += 'ru/';
      }
      return hp;
    },
  },
  created () {
    this.setInitParam()
  },
  methods: {
    ...mapActions('user', ['logout']),
    logoutBtnClick () {
      this.logout()
        .then(res => {
          if (res?.status === 200) {
            this.$router.push({name: 'auth'})
          }
        })
    },
    setInitParam () {
      const linkHasSended = this.$route.params?.linkHasSended
      if (linkHasSended) {
        this.linkHasSended = linkHasSended
      }
    },
    sendLink() {
      this.loadingRequest = true
      axios.post('/api/v1/email/verification-notification')
        .then(({status, data}) => {
          if (status < 300) {
            this.$toastr.success(data.status)
            if (status === 200) {
              this.showSignInLink = true
            } else if (status === 202) {
              this.linkHasSended = true
            }
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.title {
  @include media-breakpoint-down(xs) {
    font-size: 1.5rem;
  }
}
.cheader {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
